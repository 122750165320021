<template>
  <div>
    <v-card max-width="1000" class="mx-auto box-shadow" outlined>
      <v-card-title class="font-weight-bold px-7 blue-grey lighten-5">
        Profile Details
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-7">
        <v-row v-if="user">
          <v-col cols="12" md="3">
            <div class="d-flex justify-center">
              <UserPhoto
                :size="150"
                :id="user.id"
                photoSize="medium"
                editable
              />
            </div>

            <div class="caption mt-5 text-center text-sm-left">
              Last login: <br />
              {{ user.userdata.metadata.lastSignInTime }}
            </div>
          </v-col>
          <v-col cols="12" md="9">
            <v-form
              ref="profileForm"
              class="mb-7"
              @submit.prevent="validateForm()"
            >
              <v-alert
                v-if="status.error"
                type="error"
                border="left"
                transition="fade"
              >
                {{ status.error }}
              </v-alert>

              <div class="error--text mb-3" v-if="!user.userdata.emailVerified">
                <v-progress-circular
                  v-if="status.sendingVerification"
                  indeterminate
                  color="primary"
                  width="2"
                  size="12"
                  class="mr-2"
                ></v-progress-circular>
                <!-- <span>Your email address is unverified. Click <a href="#" @click.prevent="sendVerifyEmail()">here</a> to verify.</span> -->
              </div>

              <!-- <v-card v-if="user && ['dietitian', 'personal_trainer'].includes(user.role)" class="mb-5" outlined>
                  <v-card-text>
                    <div class="mb-5">Referral links</div>
                    <v-alert
                      type="info"
                      class="py-2 px-3 mb-5"
                    >
                    <p class="subtitle-2 mb-0">
                      Share these codes with friends or colleagues who you think would benefit from MEALZEE. Earn a 30% one-off spotters fee once they sign up and pay.
                    </p>
                    </v-alert>
                    <v-text-field
                      :value="`${$window.origin}${$router.resolve({ name: 'Register', query: { ref: user.id } }).href}`"
                      @click:append="
                        copy(`${$window.origin}${$router.resolve({ name: 'Register', query: { ref: user.id } }).href}`, {
                          options: {
                            onCopy: $store.dispatch('showSuccess', 'Copied to clipboard')
                          }
                        })
                      "
                      append-icon="mdi-content-copy"
                      label="Regular Member"
                      readonly
                      outlined
                      dense
                    />
                    
                    <v-text-field
                      :value="`${$window.origin}${$router.resolve({ name: 'DietitianRegistration', query: { ref: user.id } }).href}`"
                      @click:append="
                        copy(`${$window.origin}${$router.resolve({ name: 'DietitianRegistration', query: { ref: user.id } }).href}`, {
                          options: {
                            onCopy: $store.dispatch('showSuccess', 'Copied to clipboard')
                          }
                        })
                      "
                      append-icon="mdi-content-copy"
                      label="Dietitian"
                      readonly
                      outlined
                      dense
                    />
                    
                    <v-text-field
                      :value="`${$window.origin}${$router.resolve({ name: 'PtRegistration', query: { ref: user.id } }).href}`"
                      @click:append="
                        copy(`${$window.origin}${$router.resolve({ name: 'PtRegistration', query: { ref: user.id } }).href}`, {
                          options: {
                            onCopy: $store.dispatch('showSuccess', 'Copied to clipboard')
                          }
                        })
                      "
                      append-icon="mdi-content-copy"
                      label="Personal Trainer"
                      hide-details
                      readonly
                      outlined
                      dense
                    />
                  </v-card-text>
                </v-card> -->
              <div class="caption">Custom branding:</div>
              <custom-logo
                v-if="
                  user &&
                  [
                    'dietitian',
                    'personal_trainer',
                    'nutritionist',
                    'naturopath',
                  ].includes(user.role) &&
                  !user?.isStudent
                "
                class="step-logo mb-3"
                :plans="allPlans"
              />

              <v-text-field
                v-model="user.email"
                :rules="[rules.required, rules.email]"
                :append-icon="
                  user.userdata.emailVerified ? 'mdi-check-circle' : 'mdi-alert'
                "
                @click="$store.commit('user/setEmailPopup', true)"
                label="Email"
                outlined
                readonly
              ></v-text-field>

              <v-row>
                <v-col cols="12" md="6" class="py-0">
                  <v-text-field
                    v-model="user.firstName"
                    :rules="[rules.required]"
                    label="First Name"
                    outlined
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6" class="py-0">
                  <v-text-field
                    :rules="[rules.required]"
                    v-model="user.lastName"
                    label="Last Name"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-text-field
                label="Phone Number (optional)"
                v-model="user.phone"
                outlined
              ></v-text-field>

              <v-row>
                <v-col cols="12" md="6" class="py-0">
                  <v-select
                    v-model="user.gender"
                    :items="
                      user.role == 'member'
                        ? ['male', 'female']
                        : ['male', 'female', 'non-binary', 'rather not say']
                    "
                    :label="
                      user.role != 'member'
                        ? 'Gender: '
                        : 'Gender assigned a birth: '
                    "
                    outlined
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <v-menu
                    :close-on-content-click="false"
                    transition="scale-transition"
                    min-width="290px"
                    v-model="menu"
                    ref="menu"
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :value="$options.filters.formatDate2(user.birthDate)"
                        label="Birth Date"
                        readonly
                        outlined
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="user.birthDate"
                      :max="new Date().toISOString().substr(0, 10)"
                      min="1950-01-01"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <!-- <v-text-field
                  label="Associated Gym or Trainer (if applicable)"
                  v-model="user.gym"
                  outlined
                ></v-text-field> -->

              <!-- ENERGY UNIT -->
              <v-radio-group
                label="Preferred energy unit:"
                v-model="user.energyUnit"
                class="mt-0"
                row
              >
                <v-radio label="Kilojoule" value="kj" dense></v-radio>
                <v-radio label="Calorie" value="cal" dense></v-radio>
              </v-radio-group>

              <div>
                <v-text-field
                  prepend-inner-icon="mdi-account-box-outline"
                  label="Registration Number"
                  :rules="[rules.required]"
                  v-model="user.reg_num"
                  v-if="
                    user.role == 'nutritionist' || user.role == 'naturopath'
                  "
                  outlined
                ></v-text-field>
              </div>

              <!-- SOCIAL MEDIA LINKS -->
              <div v-if="user.role !== 'member'">
                <v-text-field
                  v-model="user.facebook"
                  label="Facebook"
                  outlined
                ></v-text-field>

                <v-text-field
                  v-model="user.website"
                  label="Website"
                  outlined
                ></v-text-field>

                <v-text-field
                  v-model="user.instagram"
                  label="Instagram"
                  outlined
                ></v-text-field>
              </div>

              <v-btn
                @click="validateForm()"
                :loading="status.updating"
                color="accent white--text"
                depressed
                >Update Profile</v-btn
              >
            </v-form>

            <v-form ref="passwordForm" @submit.prevent="validatePasswordForm()">
              <v-alert
                v-if="status.passwordError"
                type="error"
                border="left"
                class="mb-7"
                transition="fade"
                >{{ status.passwordError }}</v-alert
              >

              <v-text-field
                v-model="password.current"
                label="Current Password"
                :rules="[rules.required]"
                type="password"
                outlined
              ></v-text-field>

              <v-row>
                <v-col cols="12" md="6" class="py-0">
                  <v-text-field
                    v-model="password.new"
                    :rules="[rules.required, rules.password]"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPassword = !showPassword"
                    :type="showPassword ? 'text' : 'password'"
                    label="New Password"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <v-text-field
                    v-model="password.repeat"
                    :rules="[
                      rules.required,
                      () =>
                        password.new === password.repeat ||
                        'Repeat password does not matched.',
                    ]"
                    label="Repeat Password"
                    type="password"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-btn
                @click="validatePasswordForm()"
                :loading="status.updatingPassword"
                color="accent white--text"
                class="mr-2"
                depressed
                >Update Password</v-btn
              >

              <v-btn
                @click="$refs.passwordForm.reset()"
                :disabled="status.updatingPassword"
                depressed
                >Clear</v-btn
              >
            </v-form>
          </v-col>
        </v-row>

        <preloader v-else />
      </v-card-text>
    </v-card>

    <!-- POPUPS -->
    <v-dialog :value="status.showEmailPopuup" max-width="450" persistent>
      <v-card>
        <v-card-title class="font-weight-bold"
          >Update Email Address</v-card-title
        >
        <v-divider></v-divider>
        <v-card-text class="pa-7">
          <v-form ref="updateEmailForm" @submit.prevent="validateUpdateEmail()">
            <v-alert
              type="error"
              border="left"
              v-if="status.updateEmailError"
              class="mb-7"
              transition="fade"
            >
              {{ status.updateEmailError }}
            </v-alert>

            <v-text-field
              v-model="changeEmail.newEmail"
              label="New Email Address"
              :rules="[rules.required, rules.email]"
              outlined
            ></v-text-field>

            <v-text-field
              v-model="changeEmail.password"
              label="Your password"
              :rules="[rules.required]"
              type="password"
              outlined
            ></v-text-field>

            <v-btn
              @click="validateUpdateEmail()"
              :loading="status.updatingEmail"
              color="accent white--text"
              class="mr-2"
              >Update</v-btn
            >

            <v-btn
              @click="
                $store.commit('user/setEmailPopup', false);
                $refs.updateEmailForm.reset();
              "
              :disabled="status.updatingEmail"
              depressed
              >Cancel</v-btn
            >
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import rules from '@/rules';
import copy from 'copy-to-clipboard';
import { mapState, mapActions } from 'vuex';

import CustomLogo from '../meals/components/CustomLogo.vue';

export default {
  name: 'Profile',

  metaInfo: {
    title: 'Profile',
  },

  components: {
    CustomLogo,
  },

  data() {
    return {
      copy,
      rules,
      menu: false,
      showPassword: false,
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.user.user,
      allPlans: (state) => state.mealplans.plans,
      status: (state) => state.user.status,
      password: (state) => state.user.password,
      changeEmail: (state) => state.user.changeEmail,
    }),
  },

  methods: {
    ...mapActions('user', [
      'sendVerifyEmail',
      'updateUserData',
      'updatePassword',
      'updateEmail',
    ]),

    validateForm() {
      if (this.$refs.profileForm.validate()) {
        this.updateUserData();
      }
    },

    validateUpdateEmail() {
      if (this.$refs.updateEmailForm.validate()) {
        this.updateEmail();
      }
    },

    validatePasswordForm() {
      if (this.$refs.passwordForm.validate()) {
        Promise.all([this.updatePassword()]).then(() => {
          this.$refs.passwordForm.reset();
        });
      }
    },
  },

  mounted () {
    if(!this.allPlans.length) this.$store.dispatch('mealplan/getPlans')
  }
};
</script>
